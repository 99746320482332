/* -- NAVS -- */
/* ---------------------------------------------- */


// Nav links
// ----------------------------------------------
.nav {
    &.flex-column {
        flex-wrap: nowrap;
    }


    &:not(.nav-pills) .nav-link {
        &.active {
            color: $nav-tabs-link-active-color;
            font-weight: bold;
        }


        i, .nav-icon {
            color: $nav-icon-color !important;
        }
    }
}




// Nav Tabs
// ----------------------------------------------
.nav-tabs {

    // Nav tabs links
    // ----------------------------------------------
    .nav-link {
        align-items: center;
        background-color: $nav-tabs-bg;
        display: flex;
        margin-right: 1px;
        min-height: $nav-link-height;


        &.disabled {
            background-color: $nav-tabs-bg-disabled;
        }


        &:hover:not( .disabled ):not( .active ) {
            background-color: $nav-tabs-bg-hover;
        }
    }



    // Nav tabs with dropdown
    // ----------------------------------------------
    .dropdown-menu {
        border-top-left-radius: $dropdown-border-radius;
        border-top-right-radius: $dropdown-border-radius;
        margin-top: $dropdown-start-gap;
    }

}





// Tab Base
// ----------------------------------------------
.tab-base {
    .nav-tabs {
        &, .nav-link {
            border: 0;
        }
    }


    .tab-content {
        background-color: $nav-tabs-link-active-bg;
        border-top-right-radius: $nav-tabs-border-radius;
        border-bottom-left-radius: $nav-tabs-border-radius;
        border-bottom-right-radius: $nav-tabs-border-radius;
        padding: $nav-tabs-content-padding;
    }


    .nav-callout {
        background-color: $nav-tabs-link-active-bg;
        border-top-left-radius: $nav-tabs-border-radius;
        border-top-right-radius: $nav-tabs-border-radius;

        .nav-link {
            align-items: center;
            display: flex;
            min-height: $nav-link-height;
            padding-top: $nav-link-padding-x;
        }
    }


    .nav-tabs, .nav-callout {
        &.justify-content-end {
            ~ .tab-content {
                border-top-left-radius: $nav-tabs-border-radius;
                border-top-right-radius: 0;
            }
        }
    }

    &.tab-vertical {
        align-items: flex-start;
        display: flex;


        .nav-item, .nav-link {
            width: 100%;
        }


        .nav-tabs {
            .nav-link {
                border-bottom-left-radius: $nav-tabs-border-radius;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                margin-bottom: 2px;
            }
        }

        .nav-pills ~ .tab-content {
            border-radius: $nav-tabs-border-radius;
        }
    }
}





// Nav callout
// ----------------------------------------------
.nav-callout {
    border-bottom: $border-width solid $border-color;


    .nav-link {
        appearance: none;
        background-color: transparent;
        border: 0;


        &.active {
            box-shadow: inset 0 #{ 0 - $callout-width } $callout-active-state;
        }
    }
}