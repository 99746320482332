@font-face {
  font-family: "nifty-demo-icons";
  font-style: normal;
  font-weight: 400;
  src: url("font/nifty-demo-icons.woff2") format("woff2");
}

[class^="demo-pli-"],
[class*=" demo-pli-"],
[class^="demo-psi-"],
[class*=" demo-psi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "nifty-demo-icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  vertical-align: middle;
}

.demo-pli-add:before {
  content: "\e900";
}

.demo-pli-add-cart:before {
  content: "\e901";
}

.demo-pli-add-user:before {
  content: "\e902";
}

.demo-pli-add-user-star:before {
  content: "\e903";
}

.demo-pli-address-book:before {
  content: "\e904";
}

.demo-pli-arrow-down:before {
  content: "\e905";
}

.demo-pli-arrow-left:before {
  content: "\e906";
}

.demo-pli-arrow-left-2:before {
  content: "\e907";
}

.demo-pli-arrow-out-right:before {
  content: "\e908";
}

.demo-pli-arrow-right:before {
  content: "\e909";
}

.demo-pli-arrow-right-2:before {
  content: "\e90a";
}

.demo-pli-arrow-up:before {
  content: "\e90b";
}

.demo-pli-back:before {
  content: "\e90c";
}

.demo-pli-bag-coin:before {
  content: "\e90d";
}

.demo-pli-bar-chart:before {
  content: "\e90e";
}

.demo-pli-basket-coins:before {
  content: "\e90f";
}

.demo-pli-bell:before {
  content: "\e910";
}

.demo-pli-boot-2:before {
  content: "\e911";
}

.demo-pli-building:before {
  content: "\e912";
}

.demo-pli-calendar-4:before {
  content: "\e913";
}

.demo-pli-camera-2:before {
  content: "\e914";
}

.demo-pli-cart-coin:before {
  content: "\e915";
}

.demo-pli-celsius:before {
  content: "\e916";
}

.demo-pli-check:before {
  content: "\e917";
}

.demo-pli-checked-user:before {
  content: "\e918";
}

.demo-pli-chevron-down:before {
  content: "\ea5a";
}

.demo-pli-chevron-left:before {
  content: "\ea5b";
}

.demo-pli-chevron-right:before {
  content: "\ea5c";
}

.demo-pli-chevron-up:before {
  content: "\ea5d";
}

.demo-pli-clock:before {
  content: "\e919";
}

.demo-pli-close:before {
  content: "\e91a";
}

.demo-pli-coding:before {
  content: "\e91b";
}

.demo-pli-coin:before {
  content: "\e91c";
}

.demo-pli-computer-secure:before {
  content: "\e91d";
}

.demo-pli-consulting:before {
  content: "\e91e";
}

.demo-pli-credit-card-2:before {
  content: "\e91f";
}

.demo-pli-crop-2:before {
  content: "\e920";
}

.demo-pli-cross:before {
  content: "\ea52";
}

.demo-pli-data-cloud:before {
  content: "\e921";
}

.demo-pli-data-settings:before {
  content: "\e922";
}

.demo-pli-data-storage:before {
  content: "\e923";
}

.demo-pli-dot-horizontal:before {
  content: "\ea66";
}

.demo-pli-dot-vertical:before {
  content: "\ea67";
}

.demo-pli-download-from-cloud:before {
  content: "\e924";
}

.demo-pli-download-window:before {
  content: "\e925";
}

.demo-pli-electricity:before {
  content: "\e926";
}

.demo-pli-exclamation:before {
  content: "\e927";
}

.demo-pli-facebook:before {
  content: "\e928";
}

.demo-pli-facebook-2:before {
  content: "\e929";
}

.demo-pli-female-2:before {
  content: "\e92a";
}

.demo-pli-file:before {
  content: "\e92b";
}

.demo-pli-file-add:before {
  content: "\e92c";
}

.demo-pli-file-csv:before {
  content: "\e92d";
}

.demo-pli-file-edit:before {
  content: "\e92e";
}

.demo-pli-file-excel:before {
  content: "\e92f";
}

.demo-pli-file-html:before {
  content: "\e930";
}

.demo-pli-file-jpg:before {
  content: "\e931";
}

.demo-pli-file-music:before {
  content: "\e932";
}

.demo-pli-file-pictures:before {
  content: "\e933";
}

.demo-pli-file-search:before {
  content: "\e934";
}

.demo-pli-file-text-image:before {
  content: "\e935";
}

.demo-pli-file-txt:before {
  content: "\e936";
}

.demo-pli-file-word:before {
  content: "\e937";
}

.demo-pli-file-zip:before {
  content: "\e938";
}

.demo-pli-find-user:before {
  content: "\e939";
}

.demo-pli-fire-flame-2:before {
  content: "\e93a";
}

.demo-pli-firewall:before {
  content: "\e93b";
}

.demo-pli-folder:before {
  content: "\e93c";
}

.demo-pli-folder-organizing:before {
  content: "\e93d";
}

.demo-pli-folder-search:before {
  content: "\e93e";
}

.demo-pli-folder-with-document:before {
  content: "\e93f";
}

.demo-pli-folder-zip:before {
  content: "\e940";
}

.demo-pli-gear:before {
  content: "\e941";
}

.demo-pli-gears:before {
  content: "\e942";
}

.demo-pli-go-top:before {
  content: "\e943";
}

.demo-pli-google-plus:before {
  content: "\e944";
}

.demo-pli-happy:before {
  content: "\e945";
}

.demo-pli-heart-2:before {
  content: "\e946";
}

.demo-pli-home:before {
  content: "\e947";
}

.demo-pli-idea:before {
  content: "\e948";
}

.demo-pli-idea-2:before {
  content: "\e949";
}

.demo-pli-inbox-full:before {
  content: "\e94a";
}

.demo-pli-inbox-into:before {
  content: "\e94b";
}

.demo-pli-information:before {
  content: "\e94c";
}

.demo-pli-instagram:before {
  content: "\e94d";
}

.demo-pli-internet:before {
  content: "\e94e";
}

.demo-pli-laptop:before {
  content: "\e94f";
}

.demo-pli-laughing:before {
  content: "\e950";
}

.demo-pli-layout-grid:before {
  content: "\e951";
}

.demo-pli-left-4:before {
  content: "\e952";
}

.demo-pli-like:before {
  content: "\e953";
}

.demo-pli-like-2:before {
  content: "\e954";
}

.demo-pli-lock-2:before {
  content: "\e955";
}

.demo-pli-lock-user:before {
  content: "\e956";
}

.demo-pli-love:before {
  content: "\e957";
}

.demo-pli-love-user:before {
  content: "\e958";
}

.demo-pli-magnifi-glass:before {
  content: "\e959";
}

.demo-pli-mail:before {
  content: "\e95a";
}

.demo-pli-mail-attachment:before {
  content: "\e95b";
}

.demo-pli-mail-block:before {
  content: "\e95c";
}

.demo-pli-mail-favorite:before {
  content: "\e95d";
}

.demo-pli-mail-remove:before {
  content: "\e95e";
}

.demo-pli-mail-send:before {
  content: "\e95f";
}

.demo-pli-mail-unread:before {
  content: "\e960";
}

.demo-pli-male:before {
  content: "\e961";
}

.demo-pli-male-2:before {
  content: "\e962";
}

.demo-pli-male-female:before {
  content: "\e963";
}

.demo-pli-map:before {
  content: "\e964";
}

.demo-pli-map-2:before {
  content: "\e965";
}

.demo-pli-map-marker:before {
  content: "\e966";
}

.demo-pli-map-marker-2:before {
  content: "\e967";
}

.demo-pli-maximize-2:before {
  content: "\ea53";
}

.demo-pli-maximize-3:before {
  content: "\ea5e";
}

.demo-pli-medal-2:before {
  content: "\e968";
}

.demo-pli-min:before {
  content: "\ea54";
}

.demo-pli-mine:before {
  content: "\e969";
}

.demo-pli-minimize-2:before {
  content: "\ea55";
}

.demo-pli-minimize-3:before {
  content: "\ea5f";
}

.demo-pli-monitor-2:before {
  content: "\e96a";
}

.demo-pli-monitor-tablet:before {
  content: "\e96b";
}

.demo-pli-next:before {
  content: "\e96c";
}

.demo-pli-office:before {
  content: "\e96d";
}

.demo-pli-old-telephone:before {
  content: "\e96e";
}

.demo-pli-palette:before {
  content: "\e96f";
}

.demo-pli-paper-plane:before {
  content: "\e970";
}

.demo-pli-paperclip:before {
  content: "\e971";
}

.demo-pli-pen-4:before {
  content: "\e972";
}

.demo-pli-pen-5:before {
  content: "\e973";
}

.demo-pli-pencil:before {
  content: "\e974";
}

.demo-pli-photo-2:before {
  content: "\e975";
}

.demo-pli-photos:before {
  content: "\e976";
}

.demo-pli-previous:before {
  content: "\e977";
}

.demo-pli-printer:before {
  content: "\e978";
}

.demo-pli-question:before {
  content: "\e979";
}

.demo-pli-receipt-4:before {
  content: "\e97a";
}

.demo-pli-recycling:before {
  content: "\e97b";
}

.demo-pli-refresh:before {
  content: "\e97c";
}

.demo-pli-reload-3:before {
  content: "\e97d";
}

.demo-pli-remove:before {
  content: "\e97e";
}

.demo-pli-remove-user:before {
  content: "\e97f";
}

.demo-pli-repair:before {
  content: "\e980";
}

.demo-pli-repeat-2:before {
  content: "\e981";
}

.demo-pli-right-4:before {
  content: "\e982";
}

.demo-pli-share:before {
  content: "\e983";
}

.demo-pli-shopping-bag:before {
  content: "\e984";
}

.demo-pli-shopping-basket:before {
  content: "\e985";
}

.demo-pli-shopping-cart:before {
  content: "\e986";
}

.demo-pli-sidebar-window:before {
  content: "\e987";
}

.demo-pli-smartphone-3:before {
  content: "\e988";
}

.demo-pli-smile:before {
  content: "\e989";
}

.demo-pli-speech-bubble-2:before {
  content: "\e98a";
}

.demo-pli-speech-bubble-3:before {
  content: "\e98b";
}

.demo-pli-speech-bubble-4:before {
  content: "\e98c";
}

.demo-pli-speech-bubble-5:before {
  content: "\e98d";
}

.demo-pli-speech-bubble-7:before {
  content: "\e98e";
}

.demo-pli-split-vertical-2:before {
  content: "\e98f";
}

.demo-pli-star:before {
  content: "\e990";
}

.demo-pli-support:before {
  content: "\e991";
}

.demo-pli-tablet-2:before {
  content: "\e992";
}

.demo-pli-tactic:before {
  content: "\e993";
}

.demo-pli-tag:before {
  content: "\e994";
}

.demo-pli-tag-2:before {
  content: "\e995";
}

.demo-pli-tag-3:before {
  content: "\e996";
}

.demo-pli-tag-4:before {
  content: "\e997";
}

.demo-pli-tag-5:before {
  content: "\e998";
}

.demo-pli-temperature:before {
  content: "\e999";
}

.demo-pli-thunder:before {
  content: "\e99a";
}

.demo-pli-trash:before {
  content: "\e99b";
}

.demo-pli-twitter:before {
  content: "\e99c";
}

.demo-pli-twitter-2:before {
  content: "\e99d";
}

.demo-pli-unlike:before {
  content: "\e99e";
}

.demo-pli-unlike-2:before {
  content: "\e99f";
}

.demo-pli-unlock:before {
  content: "\e9a0";
}

.demo-pli-upload-to-cloud:before {
  content: "\e9a1";
}

.demo-pli-video:before {
  content: "\e9a2";
}

.demo-pli-list-view:before {
  content: "\e9a3";
}

.demo-pli-wallet-2:before {
  content: "\e9a4";
}

.demo-pli-warning-window:before {
  content: "\e9a5";
}

.demo-pli-window-2:before {
  content: "\e9a6";
}

.demo-pli-wrench:before {
  content: "\e9a7";
}

.demo-pli-yes:before {
  content: "\e9a8";
}

.demo-psi-add:before {
  content: "\e9a9";
}

.demo-psi-add-cart:before {
  content: "\e9aa";
}

.demo-psi-add-user:before {
  content: "\e9ab";
}

.demo-psi-add-user-star:before {
  content: "\e9ac";
}

.demo-psi-address-book:before {
  content: "\e9ad";
}

.demo-psi-arrow-down:before {
  content: "\e9ae";
}

.demo-psi-arrow-left:before {
  content: "\e9af";
}

.demo-psi-arrow-left-2:before {
  content: "\e9b0";
}

.demo-psi-arrow-out-right:before {
  content: "\e9b1";
}

.demo-psi-arrow-right:before {
  content: "\e9b2";
}

.demo-psi-arrow-right-2:before {
  content: "\e9b3";
}

.demo-psi-arrow-up:before {
  content: "\e9b4";
}

.demo-psi-back:before {
  content: "\e9b5";
}

.demo-psi-bag-coin:before {
  content: "\e9b6";
}

.demo-psi-bar-chart:before {
  content: "\e9b7";
}

.demo-psi-basket-coins:before {
  content: "\e9b8";
}

.demo-psi-bell:before {
  content: "\e9b9";
}

.demo-psi-boot-2:before {
  content: "\e9ba";
}

.demo-psi-building:before {
  content: "\e9bb";
}

.demo-psi-calendar-4:before {
  content: "\e9bc";
}

.demo-psi-camera-2:before {
  content: "\e9bd";
}

.demo-psi-cart-coin:before {
  content: "\e9be";
}

.demo-psi-celsius:before {
  content: "\e9bf";
}

.demo-psi-check:before {
  content: "\e9c0";
}

.demo-psi-checked-user:before {
  content: "\e9c1";
}

.demo-psi-chevron-down:before {
  content: "\ea60";
}

.demo-psi-chevron-left:before {
  content: "\ea61";
}

.demo-psi-chevron-right:before {
  content: "\ea62";
}

.demo-psi-chevron-up:before {
  content: "\ea63";
}

.demo-psi-clock:before {
  content: "\e9c2";
}

.demo-psi-close:before {
  content: "\e9c3";
}

.demo-psi-coding:before {
  content: "\e9c4";
}

.demo-psi-coin:before {
  content: "\e9c5";
}

.demo-psi-computer-secure:before {
  content: "\e9c6";
}

.demo-psi-consulting:before {
  content: "\e9c7";
}

.demo-psi-credit-card-2:before {
  content: "\e9c8";
}

.demo-psi-crop-2:before {
  content: "\e9c9";
}

.demo-psi-cross:before {
  content: "\ea56";
}

.demo-psi-data-cloud:before {
  content: "\e9ca";
}

.demo-psi-data-settings:before {
  content: "\e9cb";
}

.demo-psi-data-storage:before {
  content: "\e9cc";
}

.demo-psi-dot-horizontal:before {
  content: "\ea68";
}

.demo-psi-dot-vertical:before {
  content: "\ea69";
}

.demo-psi-download-from-cloud:before {
  content: "\e9cd";
}

.demo-psi-download-window:before {
  content: "\e9ce";
}

.demo-psi-electricity:before {
  content: "\e9cf";
}

.demo-psi-exclamation:before {
  content: "\e9d0";
}

.demo-psi-facebook:before {
  content: "\e9d1";
}

.demo-psi-facebook-2:before {
  content: "\e9d2";
}

.demo-psi-female-2:before {
  content: "\e9d3";
}

.demo-psi-file:before {
  content: "\e9d4";
}

.demo-psi-file-add:before {
  content: "\e9d5";
}

.demo-psi-file-csv:before {
  content: "\e9d6";
}

.demo-psi-file-edit:before {
  content: "\e9d7";
}

.demo-psi-file-excel:before {
  content: "\e9d8";
}

.demo-psi-file-html:before {
  content: "\e9d9";
}

.demo-psi-file-jpg:before {
  content: "\e9da";
}

.demo-psi-file-music:before {
  content: "\e9db";
}

.demo-psi-file-pictures:before {
  content: "\e9dc";
}

.demo-psi-file-search:before {
  content: "\e9dd";
}

.demo-psi-file-text-image:before {
  content: "\e9de";
}

.demo-psi-file-txt:before {
  content: "\e9df";
}

.demo-psi-file-word:before {
  content: "\e9e0";
}

.demo-psi-file-zip:before {
  content: "\e9e1";
}

.demo-psi-find-user:before {
  content: "\e9e2";
}

.demo-psi-fire-flame-2:before {
  content: "\e9e3";
}

.demo-psi-firewall:before {
  content: "\e9e4";
}

.demo-psi-folder:before {
  content: "\e9e5";
}

.demo-psi-folder-organizing:before {
  content: "\e9e6";
}

.demo-psi-folder-search:before {
  content: "\e9e7";
}

.demo-psi-folder-with-document:before {
  content: "\e9e8";
}

.demo-psi-folder-zip:before {
  content: "\e9e9";
}

.demo-psi-gear:before {
  content: "\e9ea";
}

.demo-psi-gears:before {
  content: "\e9eb";
}

.demo-psi-go-top:before {
  content: "\e9ec";
}

.demo-psi-google-plus:before {
  content: "\e9ed";
}

.demo-psi-happy:before {
  content: "\e9ee";
}

.demo-psi-heart-2:before {
  content: "\e9ef";
}

.demo-psi-home:before {
  content: "\e9f0";
}

.demo-psi-idea:before {
  content: "\e9f1";
}

.demo-psi-idea-2:before {
  content: "\e9f2";
}

.demo-psi-inbox-full:before {
  content: "\e9f3";
}

.demo-psi-inbox-into:before {
  content: "\e9f4";
}

.demo-psi-information:before {
  content: "\e9f5";
}

.demo-psi-instagram:before {
  content: "\e9f6";
}

.demo-psi-internet:before {
  content: "\e9f7";
}

.demo-psi-laptop:before {
  content: "\e9f8";
}

.demo-psi-laughing:before {
  content: "\e9f9";
}

.demo-psi-layout-grid:before {
  content: "\e9fa";
}

.demo-psi-left-4:before {
  content: "\e9fb";
}

.demo-psi-like:before {
  content: "\e9fc";
}

.demo-psi-like-2:before {
  content: "\e9fd";
}

.demo-psi-lock-2:before {
  content: "\e9fe";
}

.demo-psi-lock-user:before {
  content: "\e9ff";
}

.demo-psi-love:before {
  content: "\ea00";
}

.demo-psi-love-user:before {
  content: "\ea01";
}

.demo-psi-magnifi-glass:before {
  content: "\ea02";
}

.demo-psi-mail:before {
  content: "\ea03";
}

.demo-psi-mail-attachment:before {
  content: "\ea04";
}

.demo-psi-mail-block:before {
  content: "\ea05";
}

.demo-psi-mail-favorite:before {
  content: "\ea06";
}

.demo-psi-mail-remove:before {
  content: "\ea07";
}

.demo-psi-mail-send:before {
  content: "\ea08";
}

.demo-psi-mail-unread:before {
  content: "\ea09";
}

.demo-psi-male:before {
  content: "\ea0a";
}

.demo-psi-male-2:before {
  content: "\ea0b";
}

.demo-psi-male-female:before {
  content: "\ea0c";
}

.demo-psi-map:before {
  content: "\ea0d";
}

.demo-psi-map-2:before {
  content: "\ea0e";
}

.demo-psi-map-marker:before {
  content: "\ea0f";
}

.demo-psi-map-marker-2:before {
  content: "\ea10";
}

.demo-psi-maximize-2:before {
  content: "\ea57";
}

.demo-psi-maximize-3:before {
  content: "\ea64";
}

.demo-psi-medal-2:before {
  content: "\ea11";
}

.demo-psi-min:before {
  content: "\ea58";
}

.demo-psi-mine:before {
  content: "\ea12";
}

.demo-psi-minimize-2:before {
  content: "\ea59";
}

.demo-psi-minimize-3:before {
  content: "\ea65";
}

.demo-psi-monitor-2:before {
  content: "\ea13";
}

.demo-psi-monitor-tablet:before {
  content: "\ea14";
}

.demo-psi-next:before {
  content: "\ea15";
}

.demo-psi-office:before {
  content: "\ea16";
}

.demo-psi-old-telephone:before {
  content: "\ea17";
}

.demo-psi-palette:before {
  content: "\ea18";
}

.demo-psi-paper-plane:before {
  content: "\ea19";
}

.demo-psi-paperclip:before {
  content: "\ea1a";
}

.demo-psi-pen-4:before {
  content: "\ea1b";
}

.demo-psi-pen-5:before {
  content: "\ea1c";
}

.demo-psi-pencil:before {
  content: "\ea1d";
}

.demo-psi-photo-2:before {
  content: "\ea1e";
}

.demo-psi-photos:before {
  content: "\ea1f";
}

.demo-psi-previous:before {
  content: "\ea20";
}

.demo-psi-printer:before {
  content: "\ea21";
}

.demo-psi-question:before {
  content: "\ea22";
}

.demo-psi-receipt-4:before {
  content: "\ea23";
}

.demo-psi-recycling:before {
  content: "\ea24";
}

.demo-psi-refresh:before {
  content: "\ea25";
}

.demo-psi-reload-3:before {
  content: "\ea26";
}

.demo-psi-remove:before {
  content: "\ea27";
}

.demo-psi-remove-user:before {
  content: "\ea28";
}

.demo-psi-repair:before {
  content: "\ea29";
}

.demo-psi-repeat-2:before {
  content: "\ea2a";
}

.demo-psi-right-4:before {
  content: "\ea2b";
}

.demo-psi-share:before {
  content: "\ea2c";
}

.demo-psi-shopping-bag:before {
  content: "\ea2d";
}

.demo-psi-shopping-basket:before {
  content: "\ea2e";
}

.demo-psi-shopping-cart:before {
  content: "\ea2f";
}

.demo-psi-sidebar-window:before {
  content: "\ea30";
}

.demo-psi-smartphone-3:before {
  content: "\ea31";
}

.demo-psi-smile:before {
  content: "\ea32";
}

.demo-psi-speech-bubble-2:before {
  content: "\ea33";
}

.demo-psi-speech-bubble-3:before {
  content: "\ea34";
}

.demo-psi-speech-bubble-4:before {
  content: "\ea35";
}

.demo-psi-speech-bubble-5:before {
  content: "\ea36";
}

.demo-psi-speech-bubble-7:before {
  content: "\ea37";
}

.demo-psi-split-vertical-2:before {
  content: "\ea38";
}

.demo-psi-star:before {
  content: "\ea39";
}

.demo-psi-support:before {
  content: "\ea3a";
}

.demo-psi-tablet-2:before {
  content: "\ea3b";
}

.demo-psi-tactic:before {
  content: "\ea3c";
}

.demo-psi-tag:before {
  content: "\ea3d";
}

.demo-psi-tag-2:before {
  content: "\ea3e";
}

.demo-psi-tag-3:before {
  content: "\ea3f";
}

.demo-psi-tag-4:before {
  content: "\ea40";
}

.demo-psi-tag-5:before {
  content: "\ea41";
}

.demo-psi-temperature:before {
  content: "\ea42";
}

.demo-psi-thunder:before {
  content: "\ea43";
}

.demo-psi-trash:before {
  content: "\ea44";
}

.demo-psi-twitter:before {
  content: "\ea45";
}

.demo-psi-twitter-2:before {
  content: "\ea46";
}

.demo-psi-unlike:before {
  content: "\ea47";
}

.demo-psi-unlike-2:before {
  content: "\ea48";
}

.demo-psi-unlock:before {
  content: "\ea49";
}

.demo-psi-upload-to-cloud:before {
  content: "\ea4a";
}

.demo-psi-video:before {
  content: "\ea4b";
}

.demo-psi-view-list:before {
  content: "\ea4c";
}

.demo-psi-wallet-2:before {
  content: "\ea4d";
}

.demo-psi-warning-window:before {
  content: "\ea4e";
}

.demo-psi-window-2:before {
  content: "\ea4f";
}

.demo-psi-wrench:before {
  content: "\ea50";
}

.demo-psi-yes:before {
  content: "\ea51";
}

/*# sourceMappingURL=demo-icons.css.map */
