/* -- CARDS -- */
/* ---------------------------------------------- */


// Card shadows
// ----------------------------------------------
.card {
    &:not([class *="border-"]) {
        border: 0;
    }
    box-shadow: $card-box-shadow;

    &-header {
        min-height: $card-header-height;
    }
}



// Card with background color
// ----------------------------------------------
.card[ class *= "bg-" ] {
    .card-header {
        background-color: rgba( 0,0,0, .03 );
        border-bottom: $card-border-width solid $card-border-color;
    }


    :where( h1, h2, h3, h4, h5, h6 ) {
        color: inherit;
    }
}



.card-header-tabs {
    margin-bottom: -1rem;
}