/* -- TOOLBARS -- */
/* ---------------------------------------------- */


.toolbar-start {
	margin-bottom: $card-title-spacer-y;
}



.toolbar-end {
	align-items: center;
	display: inline-flex;
	flex-direction: row;

	> :not( :first-child ) {
		margin-left: $card-spacer-x * .5;
	}
}





// Responsive Toolbar
// ----------------------------------------------
@include media-breakpoint-up( "md" ) {
	.toolbar {
		align-items: center;
		display: flex;
	}

	.toolbar-start {
		flex: 1 1 0;
		margin-bottom: 0;
	}
}