/* You can add global styles to this file, and also import other style files */
@import 'assets/css/demo-purpose/demo-icons.css';
@import 'assets/scss/bootstrap';
@import 'assets/scss/nifty';
//@import url(assets/css/nifty.min.css);
//@import url(assets/premium/icon-sets/icons/line-icons/premium-line-icons.min.css);
//@import url(assets/premium/icon-sets/icons/line-icons/premium-line-icons.min.css);
// .form-select {
//   padding: 0.2rem 2rem 0.2rem 0.4rem !important;
// }
// .form-control {
//   padding: 0.2rem 1rem;
// }
