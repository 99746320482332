/* -- IMAGES -- */
/* ---------------------------------------------- */


// Various size options for images */
// ----------------------------------------------
@each $size, $val in $thumbnail-sizes {
    .img-#{ $size } {
        width: $val;
        height: $val;
    }
}