// Please  avoid modifying Bootstrap's SCSS files or Nifty's SCSS files.
// You can add your own stylesheet here or modify Bootstrap and Nifty variables to entend it.


// EXAMPLE
// ----------------------------------------------
// $border-radius						: .75rem;
// $body-bg								: #2b2b2b;
// $body-color							: #eee;
$header-bg: #93a4b5;
$brand-size: 111px;
$teal         : #61218b !default;
